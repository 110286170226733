import React from 'react'
import second from '../images/pexel1.jpg'
const HomeHeader = () => {
  return (
    <div className='Home-header-container'>
<img  style={{height:"500px"}} src={second} className='front-home-img' alt='' />
    </div>
  )
}

export default HomeHeader