import React from 'react'

const About = () => {
  return (
    <div className='container py-5 '>
    <div className='row about-container text-justify'>
      <div className='col-lg-10'>
<h1 className='text-center py-2'>About Us</h1>

<p>kamal  Trainings & Consultancy Service offers a comprehensive range of globally-recognized qualifications designed to meet the health, safety and environmental management needs of all places of work & Consultancy services.</p>

<b> <hr /></b>
<p>
  E commerce trading like shopify,daraz.com,amazon service and much  more and other courses like
NEBOSH-IGC, NEBOSH-DIPLOMA, IOSH-MANAGING SAFELY, IOSH- LEVEL 3, OSHACADMY, OSHACADMY, Incident & Accident Investigation, Kitchen Safety, Crises Management, Fire marshals/Wardens Responsibility, Safety Monitoring Training, Promotion of safe behavior in Work Area , Developing Safe culture at work Place , Risk Assessment, Emergency Preparedness & Evacuation , Job Safety Analysis, Vehicle Safety Checklist, Chemical and Biological hazard , Transportation hazards, Basic Site Safety Training for Labours, Scaﬀolding awareness, and Scaﬀolding supervisor safety training
</p>

<hr/>
<p>
We provide several qualifications up to Postgraduate level internationally through, NEBOSH (UK), IOSH (UK), TRACCERT (Canada), HSI (USA), ASNT USA, LEEA (UK), AOSH (UK), HABC (UK), OTHM (UK), ICML (USA), offer the following fields qualifications:

</p>
      </div>
    </div>
  </div>
  )
}

export default About